import { FC } from 'react'
import {
  GlobalDesktopHeaderNavMenuDropdown,
  GlobalDeskTopNavSubMenu,
} from '@components/molecules/GlobalDesktopHeaderNavMenuDropdown'
import { observer } from 'mobx-react'
import Link from 'next/dist/client/link'
import { sanitizeUrl } from '@/utils'
import styles from './index.module.scss'

export type GlobalDeskTopNavMenuItem = {
  name: string
  isMultipleColumns?: boolean
  href?: string
  isExternal?: boolean
  subMenus: GlobalDeskTopNavSubMenu[]
  isLongWidth?: boolean
}

type Props = GlobalDeskTopNavMenuItem

export const GlobalDesktopHeaderNavMenu: FC<Props> = observer(
  ({ name, subMenus, href = '', isMultipleColumns = false, isExternal = false, isLongWidth = false }) => {
    if (isExternal) {
      return (
        <a
          className={isLongWidth ? styles.longWidthContainer : styles.container}
          href={sanitizeUrl(href)}
          target='_blank'
          rel='noreferrer'
        >
          <div className={styles.containerWithoutDropDown}>{name}</div>
        </a>
      )
    }

    if (subMenus.length === 0) {
      return (
        <Link href={sanitizeUrl(href)}>
          <a className={styles.container}>
            <div className={styles.containerWithoutDropDown}>{name}</div>
          </a>
        </Link>
      )
    }

    return (
      <div className={styles.container}>
        <div className={styles.name}>{name}</div>
        <div className={styles.dropDown}>
          <GlobalDesktopHeaderNavMenuDropdown subMenus={subMenus} isMultipleColumns={isMultipleColumns} />
        </div>
      </div>
    )
  }
)
