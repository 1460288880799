import { FC, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'next-i18next'
import { DropDownListTextHeader } from '@components/molecules/DropDownListTextHeader'
import { DropDownListItemGroupContainer } from '@components/molecules/DropDownListItemGroupContainer'
import { DropDownListItems } from '@components/molecules/DropDownListItems'
import BuildingSvg from '@/public/images/common/building-01_v2.svg'
import GlobeSvg from '@/public/images/common/globe-01_v2.svg'
import AgencySvg from '@/public/images/common/briefcase-01_v2.svg'
import PeopleSvg from '@/public/images/common/people-01_v2.svg'
import { DropdownMenu } from '@components/atoms/DropdownMenu'
import { useStores } from '@/utils'
import styles from './index.module.scss'

type MenuItem = {
  text: string
  href: string
  iconSvg: ReactNode
  isExternal: boolean
}

type Props = {
  toggleDropDown: () => void
}

export const GlobalMobileHeaderServiceMenuDropdown: FC<Props> = observer(({ toggleDropDown }) => {
  const { t } = useTranslation(['global'])
  const { viewer } = useStores()
  const startupMenuItems: MenuItem[] = [
    {
      text: t('投資会社を探す'),
      href: viewer.isSignedIn ? `/${viewer.viewer?.username}/corporate-investors` : '/corporate-investors',
      iconSvg: <BuildingSvg viewBox='0 0 24 24' />,
      isExternal: false,
    },
    {
      text: t('資金調達戦略診断'),
      href: '/qa',
      iconSvg: <GlobeSvg viewBox='0 0 24 24' />,
      isExternal: false,
    },
    {
      text: t('代理店募集'),
      href: process.env.NEXT_PUBLIC_AGENCY_URL,
      iconSvg: <AgencySvg viewBox='0 0 24 24' />,
      isExternal: true,
    },
    {
      text: t('投資家パートナー募集'),
      href: process.env.NEXT_PUBLIC_INVESTOR_PARTNER_URL,
      iconSvg: <PeopleSvg viewBox='0 0 24 24' />,
      isExternal: true,
    },
    // {
    //   text: t('エンジェル投資家を探す'),
    //   href: `/${viewer.viewer?.username}/angels`,
    //   iconSvg: <UserSvg viewBox='0 0 24 24' />,
    //   isExternal: false,
    // },
  ]

  return (
    <DropdownMenu toggleDropDown={toggleDropDown}>
      <div className={styles.container}>
        <div className={styles.content}>
          <DropDownListItemGroupContainer>
            <DropDownListTextHeader>{t('投資家')}</DropDownListTextHeader>
            <DropDownListItems items={startupMenuItems} />
          </DropDownListItemGroupContainer>
          {/* <DropDownListItemDivider />

          <DropDownListItemGroupContainer>
            <DropDownListTextHeader>{t('Language')}</DropDownListTextHeader>
            <div className={styles.language}>
              <LanguageSelection />
            </div>
          </DropDownListItemGroupContainer>
          <DropDownListItemDivider /> */}
        </div>
      </div>
    </DropdownMenu>
  )
})
